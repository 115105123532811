import request, { MarkcoinRequest } from '@/plugins/request'

import {
  YapiGetV1ChainStarNewsApiRequest,
  YapiGetV1ChainStarNewsApiResponse,
} from '@/typings/yapi/ChainStarNewsV1GetApi'

import {
  YapiGetV1ChainStarBottomBarApiRequest,
  YapiGetV1ChainStarBottomBarApiResponse,
} from '@/typings/yapi/ChainStarBottomBarV1GetApi'

/**
 * [获取新闻资讯↗](https://yapi.nbttfc365.com/project/44/interface/api/5779)
 * */
export const fetchChainStarNews: MarkcoinRequest<
  YapiGetV1ChainStarNewsApiRequest,
  YapiGetV1ChainStarNewsApiResponse['data']
> = params => {
  return request({
    path: '/v1/chainStar/news',
    method: 'GET',
    params,
  })
}

/**
 * [获取公司首页底部栏↗](https://yapi.nbttfc365.com/project/44/interface/api/5789)
 * */
export const fetchChainStarBottomBar: MarkcoinRequest<
  YapiGetV1ChainStarBottomBarApiRequest,
  YapiGetV1ChainStarBottomBarApiResponse['data']
> = params => {
  return request({
    path: '/v1/chainStar/bottomBar',
    method: 'GET',
    params,
    timeout: 3 * 1000,
  })
}
