import Request, { MarkcoinRequest } from '@/plugins/request'
import {
  YapiGetV1ChainStarHomeGroupConfigApiRequest,
  YapiGetV1ChainStarHomeGroupConfigApiResponse,
} from '@/typings/yapi/ChainStarHomeGroupConfigV1GetApi'
import {
  YapiGetV1HomeColumnGetListApiRequest,
  YapiGetV1HomeColumnGetListApiResponse,
} from '@/typings/yapi/HomeColumnGetListV1GetApi'
import {
  YapiGetV1HomeWebsiteGetDataApiRequest,
  YapiGetV1HomeWebsiteGetDataApiResponse,
} from '@/typings/yapi/HomeWebsiteGetDataV1GetApi'

export const getFooterApiData: MarkcoinRequest<
  YapiGetV1HomeColumnGetListApiRequest,
  YapiGetV1HomeColumnGetListApiResponse['data']
> = params => {
  return Request({
    path: '/v1/home/column/getList',
    method: 'GET',
    params,
    timeout: 3 * 1000,
  })
}

export const getBasicWebApiData: MarkcoinRequest<
  YapiGetV1HomeWebsiteGetDataApiRequest,
  YapiGetV1HomeWebsiteGetDataApiResponse['data']
> = params => {
  return Request({
    path: '/v1/home/website/getData',
    method: 'GET',
    params,
  })
}

/**
 * [主页的配置↗](https://yapi.nbttfc365.com/project/44/interface/api/5829)
 * */
export const fetchChainStarHomeGroupConfig: MarkcoinRequest<
  YapiGetV1ChainStarHomeGroupConfigApiRequest,
  YapiGetV1ChainStarHomeGroupConfigApiResponse['data']
> = params => {
  return Request({
    path: '/v1/chainStar/homeGroupConfig',
    method: 'GET',
    params,
  })
}
